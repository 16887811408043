import React from 'react';
import './styles.css';
import logo from './image/logo.png'; // Correctly import the logo image

function Header() {
  const openMenu = () => {
    document.getElementById("sidemenu").style.right = "0";
  };

  const closeMenu = () => {
    document.getElementById("sidemenu").style.right = "-200px";
  };

  return (
    <div id="header">
      <div className="container">
        <nav>
          <img src={logo} className="logo" alt="logo" /> {/* Use the imported logo */}
          <ul id="sidemenu">
            <li><a href="#header">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
            <i className="fas fa-times" onClick={closeMenu}></i>
          </ul>
          <i className="fas fa-bars" onClick={openMenu}></i>
        </nav>
        <div className="header-text">
          <p>full stack java developer</p>
          <h1>Hi, this is <span>Msgun</span><br />Backend Developer, Full-Stack java Developer</h1>
        </div>
      </div>
    </div>
  );
}

export default Header;
