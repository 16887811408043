import React from 'react';
import aws from './image/aws.jpg';
import spring from './image/spring.jpg'; // Add this line to import the LinkedIn image
import './styles.css';

function Projects() {
  return (
    <div id="projects">
      <div className="container">
        <h1 className="sub-title">My Projects</h1>
        <div className="work-list">
          <div className="work">
            <a href="https://aws.amazon.com/what-is-aws/" target="_blank" rel="noopener noreferrer">
              <img src={aws} className='aws' alt="Project" />
              <div className="layer">
                <h3>Little About AWS</h3>
                <p>This article can help to understand AWS</p>
              </div>
            </a>
          </div>
          <div className="work">
            <a href="https://www.ibm.com/topics/java-spring-boot" target="_blank" rel="noopener noreferrer">
              <img src={spring} className='spring' alt="spring" />
              <div className="layer">
                <h3>learn about spring boot </h3>
                <p>Visit press here to learn about spring boot
                </p>
              </div>
            </a>
          </div>
          {/* Add other project blocks here */}
        </div>
      </div>
    </div>
  );
}

export default Projects;
