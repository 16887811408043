import React from 'react';
import './styles.css';
import msgun from './image/msgun.jpg';

function About() {
  const openTab = (tabname, event) => {
    let tablinks = document.getElementsByClassName("tab-links");
    let tabcontents = document.getElementsByClassName("tab-contents");

    for (let tablink of tablinks) {
      tablink.classList.remove("active-link");
    }
    for (let tabcontent of tabcontents) {
      tabcontent.classList.remove("active-tab");
    }
    event.currentTarget.classList.add("active-link");
    document.getElementById(tabname).classList.add("active-tab");
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="about-col-1">
            <img src={msgun} className='msgun' alt="About" />
          </div>
          <div className="about-col-2">
            <h1 className="sub-title">About Me</h1>
            <p>I am diligent, forward-thinking, and experienced Full Stack Java Developer with over five years of professional experience in the modern software development life cycle (SDLC).
 My expertise spans various technologies, including Java, Spring Boot and Microservices, NodeJS, React, among others.
 I have a strong foundation in object-oriented programming and data structure. I have worked on projects involving RESTful and SOAP APIs, database development, and web services. Additionally, I am well-versed in Docker, Kubernetes, Git, and other development tools.
Throughout my career, I have successfully designed and implemented microservices using Java and Spring Boot, resulting in significant improvements in system performance and reliability. I have experience with unit/integration testing using JUnit5, Apache Kafka integration for efficient event-driven communication, and modern UI development using React.
Overall, I am a dedicated and skilled software developer with a strong background in both backend and frontend technologies. I am eager to leverage my expertise in a new opportunity and continue to expand my knowledge and skills in software development.</p>
            <div className="tab-titles">
              <p className="tab-links active-link" onClick={(event) => openTab('skills', event)}>Skills</p>
              {/* <p className="tab-links" onClick={(event) => openTab('experience', event)}>Experience</p> */}
              <p className="tab-links" onClick={(event) => openTab('education', event)}>Education</p>
            </div>

            <div className="tab-contents active-tab" id="skills">
              <ul className="vertical-skills">
                <li><span>Java</span><span id="percent">90%</span></li>
                <li><span>JavaScript</span><span id="percent">70%</span></li>
                <li><span>React</span><span id="percent">95%</span></li>
                <li><span>Spring Framework </span><span id="percent">95%</span></li>
                <li><span>MySQL</span><span id="percent">70%</span></li>
                <li><span>AWS</span><span id="percent">90%</span></li>
                {/* Add other skills here */}
              </ul>
            </div>

            {/* <div className="tab-contents" id="experience">
              <ul>
                <li><span>01/2019 – 04/2022</span><br />Software Engineer at Ministry of Education, Asmara, Eritrea</li>
                <li><span>08/2017 – 10/2018</span><br />Full-Stack Java Developer at Eri-Tel, Asmara, Eritrea</li>
              </ul>
            </div> */}
            <div className="tab-contents" id="education">
              <ul>
                <li><span>2023-2025</span><br />Master's degree Maharishi International University, Fairfeild, IA, USA</li>
                <li><span>2013-2018</span><br />Bachelor's degree Eritrean Instuted of Technology college, Eritrea</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
