import React from 'react';
import Header from './Header';
import About from './About';
import Services from './Services';
import Projects from './Projects';
import Contact from './Contact';
import './styles.css';

function App() {
  return (
    <div>
      <Header />
      <About />
      <Services />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
