import React from 'react';
import './styles.css';
import linkedin from './image/linkedin.jpg';

function Services() {
  return (
    <div id="services">
      <div className="container">
        <h1 className="sub-title">My Services</h1>
        <div className="services-list">
          <div>
            <i className="fas fa-code"></i>
            {/* <h2>Application Development</h2> */}
            <h3>Full Stack Java Development</h3>
            <p>As a Full Stack Java Developer, I design and implement robust applications using Java,
               Spring Boot, and front-end technologies like React.js and Angular, 
               ensuring seamless API integration with RESTful and SOAP services.
                I manage both relational and non-relational databases, optimize performance, 
                and employ microservices architecture for flexibility. Utilizing Docker, Jenkins, 
                and Kubernetes, I streamline deployment and scaling processes. My role includes writing 
                and maintaining tests with JUnit, ensuring code quality through reviews and static analysis, 
                and developing responsive user interfaces. Additionally, I prioritize security and compliance, 
                collaborating with cross-functional teams to meet business objectives and enhance user experience.</p>
            {/* Add other services here */}
          </div>
          <div className="work">
            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
              <img src={linkedin} className='linkedin' alt="Project" />
              <div className="layer">
                <h3>Connect on LinkedIn</h3>
                <p>Visit my LinkedIn profile for more professional updates...</p>
              </div>
            </a>
          </div>
          {/* Add more service blocks here */}
        </div>
      </div>
    </div>
  );
}

export default Services;
